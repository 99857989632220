import firebase from "firebase/app";
import "firebase/auth"

const config = {
    apiKey: "AIzaSyCSIMIa4z80oydTNeKG-eJ1A8a5Mn1WOGM",
    authDomain: "drivolo-485dc.firebaseapp.com",
    projectId: "drivolo-485dc",
    storageBucket: "drivolo-485dc.appspot.com",
    messagingSenderId: "348201372991",
    appId: "1:348201372991:web:fc2203b4519984c8fc978a",
    measurementId: "G-LG6X49FCRH"
}

firebase.initializeApp(config)

export default firebase
