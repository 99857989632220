import * as XLSX from "xlsx";

/**
 * Exports data to an Excel file with specified headers.
 *
 * @param {Array} rows - Array of objects containing the data to export.
 * @param {Array} headers - Array of strings representing the column headers.
 * @returns {Promise<void>} - Resolves when the export is complete.
 */

export default async function exportToExcel(rows, headers, fileName) {
  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* Add headers */
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

  /** not working for now  
   Apply bold style to the header row */

  //   const headerRowRange = `A1:${String.fromCharCode(65 + headers.length - 1)}1`;
  //   const headerStyle = { font: { bold: true } };
  //   worksheet[headerRowRange] = { s: headerStyle };

  /*  Set the column widths in the worksheet based on the calculated values */
  const columnWidths = calculateColumnWidths(rows);
  worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
  return;
}

/**
 * Calculates column widths based on the longest value in each column of the data.
 *
 * @param {Array} data - Array of objects representing the data to export.
 * @returns {Array} - Array of column widths, one for each column.
 */

function calculateColumnWidths(data) {
  const columnWidths = [];
  const headers = Object.keys(data[0]); // Get column headers from the first object

  headers.forEach((header, index) => {
    const maxWidth = data.reduce((max, row) => {
      const cellValue = row[header];
      const cellLength =
        typeof cellValue === "string"
          ? cellValue.length
          : String(cellValue).length;
      // Find the maximum length of the value in the current column
      return Math.max(max, cellLength);
    }, 0);
    columnWidths[index] = maxWidth + 4; // Add padding for better readability
  });

  return columnWidths;
}
