import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'What exactly does Drivolo do for driving schools?',
    subtitle:
      'Drivolo is a comprehensive online platform designed to transform traditional driving school management. It allows schools to digitally track attendance, manage student onboarding, and streamline administrative tasks, all through a user-friendly interface.',
  },
  {
    title: 'How does Drivolo ensure the security of our data?',
    subtitle:
      `At Drivolo, we prioritize your data's security. Our platform is built with robust security measures including end-to-end encryption and regular audits to ensure that all data, from student records to attendance logs, is securely managed and compliant with industry standards`,
  },
  {
    title: 'What kind of technical support can we expect from Drivolo?',
    subtitle:
      'Drivolo offers comprehensive technical support to ensure your platform runs smoothly. Our dedicated support team is available via phone, email, or whatsapp to assist with any queries or issues you may encounter. We also provide detailed guides and tutorials to help you maximize the use of our platform',
  },
];

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Frequently asked questions
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={'h6'} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Faq;
