import { combineReducers } from 'redux';
// import locationReducer from './locationReducer';
import authReducer from './authReducer';
// import inventoryReducer from './inventoryReducer';
// import cartReducer from './cartReducer';
import loaderReducer from './loaderReducer';

export default combineReducers({
  // locationReducer,
  authReducer,
  // inventoryReducer,
  // cartReducer,
  loaderReducer
});
