import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "../../GeneralComponents/Container";
import { Topbar, Sidebar } from "../components";
import setGlobalLoader from "../../../redux/actions/loaderActions";
import { createVideoCategory } from "../../../redux/actions/apiActions";
import { CircularProgress } from "@mui/material";

const validationSchema = yup.object({
  title: yup.string().trim().required("Name is required."),
  googleImageId: yup.string().trim().required("googleImageId is required."),
});

const AddVideoCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const role = useSelector((state) => state.authReducer.role);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const open = isMd ? false : openSidebar;

  const handleOnSubmit = async (values) => {
    try {
      await createVideoCategory(role, values);
      toast.success("Video Category added successfully");
      navigate("/video-category");
      dispatch(setGlobalLoader(false));
    } catch (error) {
      dispatch(setGlobalLoader(false));
      console.log("create Video Category error", error);
      toast.error(
        error.response.data.msg ??
          "some error occured while creating Video Category"
      );
    }
  };

  return (
    <Box>
      <AppBar
        position={"fixed"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
        elevation={0}
      >
        <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
          <Topbar onSidebarOpen={handleSidebarOpen} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant={isMd ? "permanent" : "temporary"}
      />
      <main>
        <Box height={{ xs: 58, sm: 66, md: 71 }} />
        <br />
        <br />

        <Box overflow="hidden" marginLeft={{ md: "270px" }}>
          <Typography
            variant={"subtitle2"}
            sx={{ marginBottom: 2 }}
            fontWeight={700}
          >
            Add Video Category
          </Typography>

          <Formik
            initialValues={{
              title: "",
              googleImageId: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleOnSubmit(values)}
            enableReinitialize
          >
            {(formikProps) => {
              const {
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 4 }}
                    sx={{ padding: "10px" }}
                  >
                    <Grid item xs={12} sm={6} md={7}>
                      <Typography
                        variant={"subtitle2"}
                        sx={{ marginBottom: 2 }}
                        fontWeight={700}
                      >
                        Video Category Title
                      </Typography>
                      <TextField
                        label="Title *"
                        variant="outlined"
                        name={"title"}
                        fullWidth
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7}>
                      <Typography
                        variant={"subtitle2"}
                        sx={{ marginBottom: 2 }}
                        fontWeight={700}
                      >
                        Image
                      </Typography>
                      <TextField
                        label="Image Url *"
                        variant="outlined"
                        name={"googleImageId"}
                        fullWidth
                        type="text"
                        value={values.googleImageId}
                        onChange={(e) =>
                          e.target.value.toString().length > 10
                            ? setFieldValue(
                                "googleImageId",
                                e.target.value.substring(0, 100)
                              )
                            : setFieldValue("googleImageId", e.target.value)
                        }
                        error={
                          touched.googleImageId && Boolean(errors.googleImageId)
                        }
                        helperText={
                          touched.googleImageId && errors.googleImageId
                        }
                      />
                    </Grid>

                    <br />
                    <br />
                    <br />
                    <br />
                  </Grid>
                  <br />
                  <Button
                    component={"button"}
                    variant={"contained"}
                    fontWeight={700}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress /> : "Submit"}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </main>
    </Box>
  );
};

export default AddVideoCategory;
