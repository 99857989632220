import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Container from "../../GeneralComponents/Container";
import { Topbar, Sidebar, Table } from "../components";
import {
  deleteVideoCategory,
  getVideoCategories,
} from "../../../redux/actions/apiActions";
import setGlobalLoader from "../../../redux/actions/loaderActions";
import exportToExcel from "../../../utils/exportToExcel";

const TableComponent = ({ columns, data, title }) => {
  const theme = useTheme();

  return (
    <Box p={4}>
      <Box
        width={1}
        height={1}
        // minHeight={800}
        borderRadius={2}
        border={`2px solid ${theme.palette.divider}`}
        sx={{
          borderStyle: "dashed",
        }}
      >
        <Table
          columns={columns}
          data={data}
          export={false}
          // search
          selection={false}
          toolbar
          title={title}
        />
      </Box>
    </Box>
  );
};

const VideoCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoCategory, setVideoCategory] = useState([]);
  const theme = useTheme();
  const role = useSelector((state) => state.authReducer.role);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const handleOnDelete = async (rowData) => {
    let text = `Are you sure you want to delete ${rowData.title}`;
    if (window.confirm(text) === true) {
      try {
        await deleteVideoCategory(role, rowData._id);
        videoCategoryFunc();
        toast.success("Video Category deleted successfully");
        navigate("/video-category");
        dispatch(setGlobalLoader(false));
      } catch (error) {
        dispatch(setGlobalLoader(false));
        console.log("Delete Video Category error", error);
        toast.error(
          error.response.data.msg ??
          "some error occured while deleting Vedio category"
        );
      }
    } else {
      toast.success("Request Cancelled");
    }
  };

  const headers = ["Sr no.", "Title", "Image"];
  const columns = [
    { title: "Sr no.", field: "index", filtering: false },
    { title: "Title", field: "title", filtering: false },
    { title: "Image", field: "googleImageId", filtering: false },
    {
      title: "Actions",
      field: "",
      filtering: false,
      render: (rowData) => (
        <div className="tableActions ">
          <Tooltip title="Delete">
            <DeleteIcon onClick={() => handleOnDelete(rowData)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function videoCategoryFunc() {
    dispatch(setGlobalLoader(true));
    try {
      const vidRes = await getVideoCategories(role);
      const data = vidRes.data.data.map((d, i) => ({
        ...d,
        index: i + 1,
      }));
      setVideoCategory(data);
      dispatch(setGlobalLoader(false));
    } catch (error) {
      dispatch(setGlobalLoader(false));
      console.log("get Video Category error", error);
      toast.error(error.response.data.msg);
    }
  }

  useEffect(() => {
    videoCategoryFunc();
  }, []);

  return (
    <Box>
      <AppBar
        position={"fixed"}
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
        elevation={0}
      >
        <Container maxWidth={1} paddingY={{ xs: 1, sm: 1.5 }}>
          <Topbar onSidebarOpen={handleSidebarOpen} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant={isMd ? "permanent" : "temporary"}
      />
      <main>
        <Box height={{ xs: 58, sm: 66, md: 71 }} />
        <Box
          display="flex"
          flex="1 1 auto"
          overflow="hidden"
          paddingLeft={{ md: "256px" }}
        >
          <Box display="flex" flex="1 1 auto" overflow="hidden">
            <Box flex="1 1 auto" height="100%" overflow="auto">
              <Box
                marginTop={3}
                mx={{ xs: 3, md: 4 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component="button"
                  size="medium"
                  onClick={() => {
                    navigate("/video-category/add");
                  }}
                >
                  Add Video Category
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  component="button"
                  size="medium"
                  onClick={async () => {
                    const rows = videoCategory.map((row) => ({
                      "Sr No. ": row.index,
                      name: row.title,
                      image: row.googleImageId,
                    }));
                    await exportToExcel(rows, headers, "Video-Categories");
                  }}
                >
                  Download
                </Button>
              </Box>
              <TableComponent
                data={videoCategory}
                columns={columns}
                title="Video Categories"
              />
              <Divider />
              {/* <Container paddingY={4}>
                <Footer />
              </Container> */}
            </Box>
          </Box>
        </Box>
      </main>
    </Box>
  );
};

export default VideoCategories;
