import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Main from "../MainLayouts/Main";
import Container from "../GeneralComponents/Container";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box
        position={"relative"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"flex-start"}
              xs={12}
              md={12}
            >
              <Box>
                <h1>Privacy Policy</h1>
                <p>Last Updated: 20/12/2023</p>

                <p>Thank you for choosing Drivolo. This Privacy Policy is designed to inform you about the types of information we collect, how we use it, and the choices you have regarding your information. Please read this Privacy Policy carefully before using the App.</p>

                <h2>1. Information We Collect:</h2>

                <h3>a. Personal Information:</h3>
                <p>We may collect personal information such as your name, contact details, and identification information for the purpose of user registration, providing driving lessons, and maintaining communication.</p>

                <h3>b. Location Information:</h3>
                <p>The App may collect and use your location information to provide you with relevant driving lesson services, route navigation, and location-based features.</p>

                <h3>c. Usage Information:</h3>
                <p>We may collect information about your interactions with the App, including the pages you view, features you use, and the time and date of your activities.</p>

                <h2>2. How We Use Your Information:</h2>

                <h3>a. Providing Services:</h3>
                <p>Your personal information is used to facilitate the provision of driving lessons, scheduling, and communication between you and our instructors.</p>

                <h3>b. Improving the App:</h3>
                <p>We may use aggregated and anonymized data to analyze user behavior and preferences, helping us enhance the functionality and user experience of the App.</p>

                <h3>c. Communication:</h3>
                <p>We may use your contact information to send important updates, notifications, and promotional materials related to our driving school services. You may choose to be communicated via WhatsApp by opting in through your account settings or at sign up page.</p>

                <h2>3. Information Sharing:</h2>

                <h3>a. Instructors:</h3>
                <p>Your information may be shared with our certified driving instructors to facilitate lesson scheduling and effective communication.</p>

                <h3>b. Legal Compliance:</h3>
                <p>We may disclose your information in response to a legal obligation, court order, or government request.</p>

                <h2>4. Your Choices:</h2>

                <h3>a. Opt-Out:</h3>
                <p>You have the right to opt-out of receiving promotional emails by following the instructions provided in the email or in account section. You may also opt out of receiving communications via WhatsApp by updating your account settings.</p>

                <h3>b. Access and Update:</h3>
                <p>You can access and update your personal information by logging into your account and making the necessary changes.</p>

                <h2>5. Changes to the Privacy Policy:</h2>
                <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Please review this Privacy Policy periodically for any changes.</p>

                <h2>6. Contact Us:</h2>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:info@drivolo.com">info@drivolo.com</a>.</p>

                <p>By using Drivolo, you consent to the terms of this Privacy Policy.</p>

                <div class="contact-info">
                  <p>Thank you for choosing Drivolo.</p>
                  <p>Drivolo</p>
                  <p><a href="tel:+918143869980">+91 8143869980</a></p>
                </div>

                <div class="footer">
                  <p><a href="mailto:info@drivolo.com">info@drivolo.com</a></p>
                </div>

              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default PrivacyPolicy;
