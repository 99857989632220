/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { sendMessage } from '../../../../redux/actions/apiActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import setGlobalLoader from '../../../../redux/actions/loaderActions';
import { CircularProgress } from '@mui/material';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Your name is required'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  contactNumber: yup
    .string()
    .trim()
    .length(10, 'Please enter a valid Mobile Number')
    .required('Mobile Number is required.'),
  message: yup
    .string()
    .trim()
    .required('Please specify your message'),
});
 
const Contact = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  };

  const onSubmit = async (values) => {
    dispatch(setGlobalLoader(true))
    try {
      await sendMessage(values);
      dispatch(setGlobalLoader(false));
      toast.success("Thank You for contacting Us, We'll get back to you in 1-2 business days.");
    } catch (error) {
      dispatch(setGlobalLoader(false))
      console.log("contactUs error", error);
      toast.error("Please input correct values.");
      return
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  }); 

  return (
    <Box maxWidth={600} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h3'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          Contact us
        </Typography>
        {/* <Typography color="text.secondary" align={'center'}>
          Rather than worrying about switching offices every couple years, you
          can instead stay in the same location and grow-up from your shared
          coworking space to an office that takes up an entire floor.
        </Typography> */}
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Name"
                variant="outlined"
                color="primary"
                size="medium"
                name="name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.touched.name && Boolean(formik.errors.name)
                }
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Mobile Number"
                variant="outlined"
                color="primary"
                size="medium"
                name="contactNumber"
                fullWidth
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactNumber && Boolean(formik.errors.contactNumber)
                }
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Button
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? <CircularProgress /> : 
                "Submit"}
              </Button>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Typography color="text.secondary">
                We'll get back to you in 1-2 business days.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* <Grid item container justifyContent={'center'} xs={12}>
              <Box>
                <Typography component="p" variant="body2" align="left">
                  By clicking on "submit" you agree to our{' '}
                  <Box
                    component="a"
                    href=""
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Privacy Policy
                  </Box>
                  ,{' '}
                  <Box
                    component="a"
                    href=""
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Data Policy
                  </Box>{' '}
                  and{' '}
                  <Box
                    component="a"
                    href=""
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Cookie Policy
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
