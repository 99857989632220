export const states = [
        // "Andhra Pradesh",
        // "Arunachal Pradesh",
        // "Assam",
        // "Bihar",
        // "Chhattisgarh",
        // // union territory
        // "Chandigarh",
        // // union territory
        // "Delhi",
        // "Goa",
        // "Gujarat",
        // "Haryana",
        // "Himachal Pradesh",
        // "Jharkhand",
        // "Karnataka",
        // "Kerala",
        // "Madhya Pradesh",
        // "Maharashtra",
        // "Manipur",
        // "Meghalaya",
        // "Mizoram",
        // "Nagaland",
        // "Odisha",
        // "Punjab",
        // "Rajasthan",
        // "Sikkim",
        // "Tamil Nadu",
        {
                label: "Telangana", value: "Telangana"
        },


        // "Tripura",
        // "Uttar Pradesh",
        // "Uttarakhand",
        // "West Bengal",
]

export const STUDENT_STATUS = {
        ONBOARDED: "ONBOARDED",
        INPROGRESS: "INPROGRESS",
        COMPLETED: "COMPLETED",
}

export const PROFILES = {
        ADMIN: "ADMIN",
        ORGANIZATION: "ORGANIZATION",
}