import React from "react";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Grid container spacing={2} sx={{ marginLeft: { xs: 1 } }}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box>
            <Box component='button' onClick={() => navigate("/")} width={80} style={{ all: "unset" , cursor : "pointer" }}>
              <Box
                component={"img"}
                src='/images/drivolo-logo.png'
                height='13vh'
              />
            </Box>
            <br />
            <br />
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"end"}
              ml={2}
            >
              {/* <Box
                component="a"
                href="https://www.facebook.com/DiagEasy.in"
                target="_blank"
                width={80}
              >
                <FacebookIcon />
              </Box> */}
              {/* <Box
                component="a"
                href="https://twitter.com"
                target="_blank"
                width={80}
              >
                <TwitterIcon />
              </Box> */}
              {/* <Box
                component="a"
                href="https://www.instagram.com/diageasy.in/"
                target="_blank"
                width={80}
              >
                <InstagramIcon />
              </Box> */}
            </Box>
            <br />
          </Box>
          <Box>
            <Typography
              variant='h4'
              align={"center"}
              data-aos={"fade-up"}
              gutterBottom
              sx={{
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Quick Links
            </Typography>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline='none'
                component='button'
                onClick={() => navigate("/about")}
                color='text.primary'
                variant={"subtitle2"}
              >
                About
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline='none'
                component='button'
                onClick={() => navigate("/contact")}
                color='text.primary'
                variant={"subtitle2"}
              >
                Contact
              </Link>
              <br />
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline='none'
                component='button'
                onClick={() => navigate("/privacy-policy")}
                color='text.primary'
                variant={"subtitle2"}
              >
                Privacy Policy
              </Link>
            </Box>
            <br />
          </Box>
          {/* <Box>
            <Typography
              variant="h4"
              align={"center"}
              data-aos={"fade-up"}
              gutterBottom
              sx={{
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Explore
            </Typography>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/our-labs"
                color="text.primary"
                variant={"subtitle2"}
              >
                Our Labs
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/contact-us"
                color="text.primary"
                variant={"subtitle2"}
              >
                Contact Us
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/refund-policy"
                color="text.primary"
                variant={"subtitle2"}
              >
                Refund Policy
              </Link>
            </Box>
            <br />
          </Box> */}
          <Box>
            <Typography
              variant='h4'
              align={"center"}
              data-aos={"fade-up"}
              gutterBottom
              sx={{
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Address
            </Typography>
            <Typography
              variant='p'
              // align={'center'}
              data-aos={"fade-up"}
              // gutterBottom
              sx={{
                fontWeight: 500,
              }}
            >
              Telangana
              {/* Kh no.337, 2nd Floor,
              <br />
              Front side,
              <br />
              Satbir complex, Sultanpur,
              <br />
              New Delhi-110030. */}
            </Typography>
          </Box>
        </Box>
        <br />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* <br />
      <Typography align={"center"} variant={"subtitle2"} color="text.secondary">
        *I agree to be contacted by Diageasy over phone and email. I do not have
        any objection to receiving emails, messages (SMS or any other mode) and
        calls from Diageasy. This consent shall supersede any preferences set
        through Do Not Disturb (DND Register)/ National Customer Preference
        Register (NCPR).
      </Typography> */}
      <Grid item xs={12}>
        {/* <Divider />
        <br /> */}
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color='text.secondary'
          // gutterBottom
        >
          &copy; Drivolo. 2024. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
